import {
	addMinutes,
	addMonths,
	differenceInMilliseconds,
	format,
	formatDistance,
	formatDistanceStrict,
	formatRelative,
	getDate,
	getMonth,
	getYear,
	isValid,
	parseISO,
	startOfWeek,
	parse,
	type Locale,
} from 'date-fns';
import { format as formatTz, utcToZonedTime } from 'date-fns-tz';
import {
	arDZ,
	cs,
	da,
	de,
	enUS,
	enGB,
	enAU,
	es,
	et,
	fi,
	fr,
	hu,
	is,
	it,
	ja,
	ko,
	nb,
	nl,
	pl,
	pt,
	ptBR,
	ro,
	ru,
	sk,
	sv,
	tr,
	zhCN,
	zhTW,
} from 'date-fns/locale';
import { defaultLocale, supportedLanguagesMap } from './constants';

const locales = {
	arDZ,
	cs,
	cs_CZ: cs,
	da,
	da_DK: da,
	de,
	de_DE: de,
	enUS,
	en_US: enUS,
	'en-US': enUS,
	enGB,
	en_UK: enGB,
	'en-UK': enGB,
	'en-GB': enGB,
	en_GB: enGB,
	enAU,
	es,
	es_ES: es,
	et,
	et_EE: et,
	fi,
	fi_FI: fi,
	fr,
	fr_FR: fr,
	hu,
	hu_HU: hu,
	is,
	is_IS: is,
	it,
	it_IT: it,
	ja,
	ja_JP: ja,
	ko,
	ko_KR: ko,
	nb,
	nb_NO: nb,
	nl,
	nl_NL: nl,
	pl,
	pl_PL: pl,
	pt,
	'pt-PT': pt,
	pt_PT: pt,
	ptBR,
	'pt-BR': ptBR,
	pt_BR: ptBR,
	ro,
	ro_RO: ro,
	ru,
	ru_RU: ru,
	sk,
	sk_SK: sk,
	sv,
	sv_SE: sv,
	tr,
	'tr-TR': tr,
	tr_TR: tr,
	zhCN,
	'zh-CN': zhCN,
	zh_CN: zhCN,
	zhTW,
	'zh-TW': zhTW,
	zh_TW: zhTW,
} as const;
const nonUTCFullZoneRegExp = new RegExp(/:\d\d(\.\d+)?[-,+]\d\d:\d\d$/);
const negativeZoneRegExp = new RegExp(/:\d\d(\.\d+)?-/);
const millisecondsСoefficients = {
	seconds: 1000,
	minutes: 60000,
	// 1000 * 60
	hours: 3600000,
	// 1000 * 60 * 60
	days: 86400000,
	// 1000 * 60 * 60 * 24
	weeks: 604800000, // 1000 * 60 * 60 * 24 * 7
} as const;
export const getLocale = (locale: string): Locale => {
	const dateFnsLocale = supportedLanguagesMap[locale] || defaultLocale;

	// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly arDZ: Locale; readonly cs: Locale; readonly cs_CZ: Locale; readonly da: Locale; readonly da_DK: Locale; readonly de: Locale; ... 56 more ...; readonly zh_TW: Locale; }'.
	return locales[dateFnsLocale];
};
type DateFNSDate = Date | number;
export const isValidTimeZone = (tz: string): boolean => {
	try {
		Intl.DateTimeFormat(undefined, {
			timeZone: tz,
		});
		return true;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (ex: any) {
		return false;
	}
};
export const formatWithLocale = (date: DateFNSDate, formatStyle: string, locale: string): string =>
	format(date, formatStyle, {
		locale: getLocale(locale),
	});
export const formatTimezonedWithLocale = (
	date: DateFNSDate,
	formatStyle: string,
	locale: string,
	timeZone: string,
): string =>
	isValidTimeZone(timeZone)
		? formatTz(utcToZonedTime(date, timeZone), formatStyle, {
				locale: getLocale(locale),
				timeZone,
			})
		: formatTz(utcToZonedTime(date, 'utc'), formatStyle, {
				locale: getLocale(locale),
				timeZone: 'utc',
			});
export const formatDistanceWithLocale = (
	date: DateFNSDate,
	baseDate: DateFNSDate,
	locale: string,
	addSuffix = false,
): string =>
	formatDistance(date, baseDate, {
		locale: getLocale(locale),
		addSuffix,
	});
export const formatRelativeWithLocale = (
	date: DateFNSDate,
	baseDate: DateFNSDate,
	locale: string,
): string =>
	formatRelative(date, baseDate, {
		locale: getLocale(locale),
	});
export const formatDistanceStrictWithLocale = (
	date: DateFNSDate,
	baseDate: DateFNSDate,
	locale: string,
	addSuffix = false,
): string =>
	formatDistanceStrict(date, baseDate, {
		locale: getLocale(locale),
		addSuffix,
	});
export const weekStartDayWithLocale = (date: DateFNSDate, locale: string): DateFNSDate =>
	startOfWeek(date, {
		locale: getLocale(locale),
	});
type ParsedZoneDateTime = {
	date: Date;
	zone: string;
};
type Indxs = {
	positiveZoneStartIndex: number;
	negativeZoneStartIndex: number;
	utcZoneStartIndex: number;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ParsingDate = string | any;
/**
 * Extracts and parses the date and timezone from a given string or current date.
 * It identifies the timezone by detecting specific characters (+, -, Z) in the date string.
 * Adjusts the timezone to a standard format and provides the parsed date and zone.
 */
export const parseZoneDateTime = (parsingDate: ParsingDate): ParsedZoneDateTime => {
	const date = typeof parsingDate === 'string' ? parsingDate : new Date().toISOString();
	const indxs: Indxs = {
		positiveZoneStartIndex: date.indexOf('+'),
		negativeZoneStartIndex: negativeZoneRegExp.test(date) ? date.lastIndexOf('-') : -1,
		utcZoneStartIndex: date.indexOf('Z'),
	};
	const zoneStartIndex = Object.values(indxs).find((el) => typeof el === 'number' && el > -1);
	const parsedDate = typeof zoneStartIndex === 'number' ? date.substring(0, zoneStartIndex) : date;
	let zone = '';
	if (typeof zoneStartIndex === 'number') {
		zone = nonUTCFullZoneRegExp.test(date)
			? date.substring(zoneStartIndex, zoneStartIndex + 6)
			: `${date.substring(zoneStartIndex, zoneStartIndex + 3)}:00`;
	}
	return {
		date: parseISO(parsedDate),
		zone: indxs.utcZoneStartIndex > -1 ? '+00:00' : zone,
	};
};
const calcOffset = (zone: string): number =>
	Number.parseInt(zone, 10) * 60 +
	Number.parseInt(`${zone.substring(0, 1)}${zone.substring(4)}`, 10);
export const setTimezoneOffset = (date: Date, offsetSource: string): Date => {
	const dateOffset = date.getTimezoneOffset();
	const offset = calcOffset(parseZoneDateTime(offsetSource).zone);
	return addMinutes(date, offset + dateOffset); // plus because getTimezoneOffset returns difference in minutes (e.g. +3:00 => -180)
};

export const setNumberOffsetToISODate = (date: string, offsetHoursNumber: number): Date => {
	const parsedDate = parseZoneDateTime(date);
	const originalOffset = calcOffset(parsedDate.zone);
	const calculatedDateTime = addMinutes(parsedDate.date, offsetHoursNumber * 60 - originalOffset);
	return calculatedDateTime;
};
export const formatZonedDateTimeWithZone = (
	date: Date,
	formatString: string,
	zone: string,
): string => {
	let res = '';
	const formatedString = format(date, formatString);
	const indxs: Indxs = {
		positiveZoneStartIndex: formatedString.indexOf('+'),
		negativeZoneStartIndex: negativeZoneRegExp.test(formatedString)
			? formatedString.lastIndexOf('-')
			: -1,
		utcZoneStartIndex: formatedString.indexOf('Z'),
	};
	const zoneStartIndex = Object.values(indxs).find((el) => typeof el === 'number' && el > -1);
	if (typeof zoneStartIndex === 'number') {
		res = `${formatedString.substring(0, zoneStartIndex)}${zone}`; // e.g. 2021-12-12T12:00:00+13:00
	}

	return res;
};

/**
 * Convert date string '2021-11-30' to ISO one '2021-11-30T00:00:00.000Z'
 * On parsing error (e.g. when passed in date and format mismatch) - returns an empty string atm.
 * Adding fireErrorAnalytics() (and therefore, dependency on `@atlassian/jira-errors-handling`) adds hundreds of Kb to any package which uses utils/date-fns, which is unacceptable.
 */
export const formatDateStringToISO = (
	date?: string | null,
	formatString = 'yyyy-MM-dd',
): string => {
	const defaultReturnValue = '';
	if (date == null || date === '') {
		return defaultReturnValue;
	}
	const parsedDate = parse(date, formatString, new Date(0, 0, 0, 0, 0, 0));
	if (!isValid(parsedDate)) {
		return defaultReturnValue;
	}
	return parsedDate.toISOString(); // formatISO can have issues with timezones: https://github.com/date-fns/date-fns/issues/2151 (
};

// this is copy of diff functions from moment.js
// @ts-expect-error - TS7023 - 'monthDiff' implicitly has return type 'any' because it does not have a return type annotation and is referenced directly or indirectly in one of its return expressions.
const monthDiff = (a: Date, b: Date) => {
	if (getDate(a) < getDate(b)) {
		// end-of-month calculations work correct when the start month has more
		// days than the end month.
		return -monthDiff(b, a);
	}
	// difference in months
	const wholeMonthDiff = (getYear(b) - getYear(a)) * 12 + (getMonth(b) - getMonth(a));
	// b is in (anchor - 1 month, anchor + 1 month)
	const anchor = addMonths(a, wholeMonthDiff);
	let anchor2;
	let adjust;

	// @ts-expect-error - TS2362 - The left-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type. | TS2363 - The right-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type.
	if (b - anchor < 0) {
		anchor2 = addMonths(a, wholeMonthDiff - 1);
		// linear across the month
		// @ts-expect-error - TS2362 - The left-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type. | TS2363 - The right-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type. | TS2362 - The left-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type. | TS2363 - The right-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type.
		adjust = (b - anchor) / (anchor - anchor2);
	} else {
		anchor2 = addMonths(a, wholeMonthDiff + 1);
		// linear across the month
		// @ts-expect-error - TS2362 - The left-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type. | TS2363 - The right-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type. | TS2362 - The left-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type. | TS2363 - The right-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type.
		adjust = (b - anchor) / (anchor2 - anchor);
	}

	// check for negative zero, return zero if negative zero
	return -(wholeMonthDiff + adjust) || 0;
};
/**
 * Computes the time difference between two dates in a variety of units (years, months, etc.),
 * factoring in timezone differences for precision. This function is versatile in handling
 * different time measurements, making it suitable for a wide range of time-related computations.
 */
export const floatDifferenceOfDates = (
	endDate: Date,
	startDate: Date,
	measurement: string,
): number => {
	const zoneDelta = startDate.getTimezoneOffset() * -60000 - endDate.getTimezoneOffset() * -60000; // copied from moment

	switch (measurement) {
		case 'years':
			return monthDiff(endDate, startDate) / 12;
		case 'quarters':
			return monthDiff(endDate, startDate) / 3;
		case 'months':
			return monthDiff(endDate, startDate);
		case 'weeks':
			// @ts-expect-error - TS2362 - The left-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type. | TS2363 - The right-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type.
			return (endDate - startDate - zoneDelta) / millisecondsСoefficients.weeks;
		// negate dst
		case 'days':
			// @ts-expect-error - TS2362 - The left-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type. | TS2363 - The right-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type.
			return (endDate - startDate - zoneDelta) / millisecondsСoefficients.days;
		// negate dst
		case 'hours':
			// @ts-expect-error - TS2362 - The left-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type. | TS2363 - The right-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type.
			return (endDate - startDate) / millisecondsСoefficients.hours;
		case 'minutes':
			// @ts-expect-error - TS2362 - The left-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type. | TS2363 - The right-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type.
			return (endDate - startDate) / millisecondsСoefficients.minutes;
		case 'seconds':
			// @ts-expect-error - TS2362 - The left-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type. | TS2363 - The right-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type.
			return (endDate - startDate) / millisecondsСoefficients.seconds;
		default:
			return differenceInMilliseconds(endDate, startDate);
	}
};
