import { useRef } from 'react';
import { setMark } from './marks';

export type PerformanceMarkProps = {
	metricKey: string;
	onSet?: () => void;
};

const PerformanceMark = ({ metricKey, onSet }: PerformanceMarkProps) => {
	const renderRef = useRef(false);

	if (!renderRef.current) {
		renderRef.current = true;
		setMark(metricKey);
		onSet && onSet();
	}

	return null;
};

export default PerformanceMark;
