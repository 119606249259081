import type { Locale } from './types';

export const defaultLocale: Locale = 'enUS';
const arabicLocale: Locale = 'arDZ';

// Map moment locales that to date-fns locales not to brake existent functionality
export const supportedLanguagesMap: {
	[key: string]: string;
} = {
	ar: arabicLocale,
	ar_DZ: arabicLocale,
	ar_MA: arabicLocale,
	ar_SA: arabicLocale,
	cs_CZ: 'cs',
	cs: 'cs',
	da_DK: 'da',
	da: 'da',
	de_DE: 'de',
	de: 'de',
	en_GB: 'enGB',
	'en-GB': 'enGB',
	en_UK: defaultLocale,
	'en-UK': defaultLocale,
	en_US: defaultLocale,
	'en-US': defaultLocale,
	en_AU: 'enAU',
	es_ES: 'es',
	es: 'es',
	et_EE: 'et',
	et: 'et',
	fi_FI: 'fi',
	fi: 'fi',
	fr_FR: 'fr',
	fr: 'fr',
	hu_HU: 'hu',
	hu: 'hu',
	is_IS: 'is',
	is: 'is',
	it_IT: 'it',
	it: 'it',
	ja_JP: 'ja',
	ja: 'ja',
	ko_KR: 'ko',
	ko: 'ko',
	nb_NO: 'nb',
	nb: 'nb',
	nl_NL: 'nl',
	nl: 'nl',
	pl_PL: 'pl',
	pl: 'pl',
	pt_BR: 'ptBR',
	'pt-BR': 'ptBR',
	pt_PT: 'pt',
	pt: 'pt',
	ro_RO: 'ro',
	ro: 'ro',
	ru_RU: 'ru',
	ru: 'ru',
	sk_SK: 'sk',
	sk: 'sk',
	sv_SE: 'sv',
	sv: 'sv',
	tr: 'tr',
	tr_TR: 'tr',
	zh_CN: 'zhCN',
	'zh-CN': 'zhCN',
	zh_TW: 'zhTW',
	'zh-TW': 'zhTW',
};
