// eslint-disable-next-line jira/restricted/@atlassian+jira-common-legacy
import { getTenantContext_DEPRECATED_DO_NOT_USE } from '@atlassian/jira-common-util-get-tenant-context';
import type { Bootstrap } from '../types';

const bootstrap: Bootstrap =
	() =>
	({ setState, getState }, { getMeta }) => {
		if (getState().tenantContext) return;
		const tenantContext = getTenantContext_DEPRECATED_DO_NOT_USE(getMeta);
		setState({ tenantContext });
	};

export { bootstrap };
