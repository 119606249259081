import memoizeOne from 'memoize-one';
import getMeta from '@atlassian/jira-get-meta';
import { getSSRMark } from './ssr-mark';
import { getSSRTimings } from './timings';

const getSSRFeatureFlags = memoizeOne(() => {
	try {
		const metaTagValue = getMeta('spa-service-flags');
		if (metaTagValue === null) return null;
		return JSON.parse(metaTagValue) || null;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		return null;
	}
});

export const ssr = {
	getDoneMark: getSSRMark,
	getTimings: getSSRTimings,
	getFeatureFlags: getSSRFeatureFlags,
} as const;

export const ssrExtended = {
	...ssr,
	includeFeatureFlags: 'when-available',
} as const;
