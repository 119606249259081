import { merge } from 'icepick';
import defaultOptions from '@atlassian/jira-common-constants/src/fetch-default-options';
import FetchError from './errors';
import { getReroutableURL } from './get-reroutable-url';
import { applyObservabilityHeaders } from './observability-headers';
import { getTraceId } from './trace-id';

/**
 * Fetch from the given URL, returning the json response.
 * <p>
 * If there are any HTTP errors the promise is rejected with a {@link FetchError}.
 *
 * @param {string} url - to fetch the JSON from
 * @param {object} options - for the fetch call
 * @returns promise containing the JSON
 * @throws {@link FetchError} if there was an HTTP Error. This will reject the promise.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default <TResponse = any,>(url: string, options: RequestInit = {}): Promise<TResponse> =>
	fetch(getReroutableURL(url), applyObservabilityHeaders(url, merge(defaultOptions, options))).then(
		(response) => {
			if (!response.ok) {
				const traceId = getTraceId(response);
				if (traceId !== undefined && traceId.length > 0) {
					throw new FetchError(
						response.status,
						`Fetch call failed with status code: ${response.status}`,
						traceId,
						response,
					);
				} else {
					throw new FetchError(response.status, undefined, undefined, response);
				}
			}

			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return response.json() as Promise<TResponse>;
		},
	);
