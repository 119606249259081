import { createContainer, createHook, createSubscriber } from 'react-sweet-state';
import type { TenantContext } from '@atlassian/jira-shared-types';
import * as actions from '../../actions';
import Store from '../../store';
import type { Actions, ContainerProps, State } from '../../types';

export const TenantContextContainer = createContainer<State, Actions, ContainerProps>(Store, {
	onInit:
		() =>
		({ dispatch }) =>
			dispatch(actions.bootstrap()),
});

export const TenantContextSubscriber = createSubscriber<State, Actions>(Store, {
	displayName: 'TenantContextSubscriber',
});

/**
 * @deprecated use individual hooks for the specific things you want.
 * @see module:allTenantContextHooks
 */
export const useTenantContextHook = createHook(Store);

/**
 * @deprecated use individual hooks for the specific things you want.
 * @see module:allTenantContextHooks
 */
export const useTenantContext = (): TenantContext => {
	const [{ tenantContext }] = useTenantContextHook();

	return tenantContext;
};
