export const getRouterResourceMetrics = (): {
	ssrRouterResourceTimeouts: string[];
} => {
	const ssrRouterResourceTimeouts = window.SPA_STATE
		? Object.keys(window.SPA_STATE).filter((key) => {
				if (key !== key.toUpperCase()) {
					return false;
				}

				const resource = window.SPA_STATE[key];
				if (typeof resource !== 'object') {
					return false;
				}

				const resourceResponse = resource[Object.keys(resource)[0]];
				return resourceResponse?.error?.name === 'TimeoutError';
			})
		: [];

	return { ssrRouterResourceTimeouts };
};
