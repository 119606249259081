import { getMark } from './marks';
/**
 * Determines the elapsed time between two specified performance marks. Useful for performance analysis by measuring the duration between key events in the application's execution flow. Ensures accurate timing metrics for optimization efforts.
 */
export const timeBetweenMarks = (startMarkName: string, endMarkName: string): number | null => {
	const startMark = getMark(startMarkName);
	const endMark = getMark(endMarkName);
	if (!startMark || !endMark || startMark.startTime > endMark.startTime) return null;
	return endMark.startTime - startMark.startTime;
};
