import cloneDeep from 'lodash/cloneDeep';
/**
 * Provides a comprehensive overview of server-side rendering performance by aggregating
 * timing metrics from various phases of the rendering process. This helps in identifying
 * potential bottlenecks and areas for optimization in the SSR lifecycle.
 */
export const getSSRTimings = () => {
	if (!window.SPA_STATE) {
		return null;
	}
	const detailRenderTrace = window.SPA_STATE['ssr/detail_render_trace'];
	const detailRequestTrace = window.SPA_STATE['ssr/detail_request_trace'];
	const highLevelMetrics = window.SPA_STATE['ssr/metrics'];
	const timings = cloneDeep(detailRenderTrace?.data?.traces) || {};
	if (highLevelMetrics?.data) {
		if ('ssrTotal' in highLevelMetrics.data) {
			timings.total = {
				startTime: 0,
				duration: highLevelMetrics.data.ssrTotal,
			};
		}
		if ('ssrGetStyle' in highLevelMetrics.data) {
			timings.styles = {
				startTime: highLevelMetrics.data.ssrGetStyle - highLevelMetrics.data.ssrGetStyle,
				duration: highLevelMetrics.data.ssrGetStyle,
			};
		}
		if ('ssrPrefetch' in highLevelMetrics.data) {
			const prefetchStartTime = Object.values(detailRenderTrace?.data?.traces || {}).reduce<{
				startTime: number;
				// @ts-expect-error - TS2345 - Argument of type '(min: { startTime: number; }, { startTime }: { startTime: any; }) => any' is not assignable to parameter of type '(previousValue: { startTime: number; }, currentValue: unknown, currentIndex: number, array: unknown[]) => { startTime: number; }'. | TS2367 - This condition will always return 'false' since the types '{ startTime: number; }' and 'number' have no overlap.
			}>((min, { startTime }) => (min === -1 || min > startTime ? startTime : min), -1);
			timings.prefetch = {
				startTime: prefetchStartTime,
				duration: highLevelMetrics.data.ssrPrefetch,
			};
		}
	}
	if (detailRequestTrace?.data) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		Object.entries(detailRequestTrace.data).forEach(([key, value]: [any, any]) => {
			timings[`prefetch/${key}`] = value;
		});
	}
	return timings;
};
