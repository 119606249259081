import React, { type ComponentType } from 'react';
import { lazy } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type DashboardPermissionErrorPageType from './ui/dashboard-permission-error-page';
import type GenericPermissionErrorPageType from './ui/generic-permission-error-page';
import type { JSErrorPageProps } from './ui/js-error-page';
import type WallboardPermissionErrorPageType from './ui/wallboard-permission-error-page';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyJSErrorPage = lazy<ComponentType<JSErrorPageProps>>(
	() => import(/* webpackChunkName: "async-js-error-page" */ './ui/js-error-page'),
);

export const JSErrorPageAsync = (props: JSErrorPageProps) => (
	<Placeholder name="async-js-error-page" fallback={null}>
		<LazyJSErrorPage {...props} />
	</Placeholder>
);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyNotFoundErrorPage = lazy<ComponentType<{}>>(
	() => import(/* webpackChunkName: "async-not-found-error-page" */ './ui/not-found-error-page'),
);

export const NotFoundErrorPageAsync = () => (
	<Placeholder name="async-not-found-error-page" fallback={null}>
		<LazyNotFoundErrorPage />
	</Placeholder>
);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyDashboardPermissionErrorPage = lazy<typeof DashboardPermissionErrorPageType>(
	() =>
		import(
			/* webpackChunkName: "async-dashboard-permission-error-page" */ './ui/dashboard-permission-error-page'
		),
);

export const DashboardPermissionErrorPageAsync = () => (
	<Placeholder name="async-dashboard-permission-error-page" fallback={null}>
		<LazyDashboardPermissionErrorPage />
	</Placeholder>
);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyWallboardPermissionErrorPage = lazy<typeof WallboardPermissionErrorPageType>(
	() =>
		import(
			/* webpackChunkName: "async-wallboard-permission-error-page" */ './ui/wallboard-permission-error-page'
		),
);

export const WallboardPermissionErrorPageAsync = () => (
	<Placeholder name="async-wallboard-permission-error-page" fallback={null}>
		<LazyWallboardPermissionErrorPage />
	</Placeholder>
);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyGenericPermissionErrorPage = lazy<typeof GenericPermissionErrorPageType>(
	() =>
		import(
			/* webpackChunkName: "async-generic-permission-error-page" */ './ui/generic-permission-error-page'
		),
);

export const GenericPermissionErrorPageAsync = () => (
	<Placeholder name="async-generic-permission-error-page" fallback={null}>
		<LazyGenericPermissionErrorPage />
	</Placeholder>
);
