import isUndefined from 'lodash/isUndefined';
import last from 'lodash/last';
import omitBy from 'lodash/omitBy';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import {
	PAGE_EVENT_ACTION,
	SCREEN_EVENT_TYPE,
	TRACK_EVENT_TYPE,
	OPERATIONAL_EVENT_TYPE,
	UI_EVENT_TYPE,
} from './events';
import {
	getSources,
	getAction,
	getAnalyticsType,
	getComponents,
	getActionSubjects,
	getActionSubjectId,
	getName,
	getContainerIds,
	getContainers,
	getContainerTypes,
	getObjectIds,
	getObjectTypes,
	getTags,
	getAttributes,
} from './extract-data-from-event';
import type { StructuredAnalytics } from './types';

const formatActionSubjectId = (name: string, actionSubject: string | undefined) =>
	actionSubject ? `${name}${actionSubject[0].toUpperCase()}${actionSubject.substring(1)}` : name;

const getActionSubjectForEventType = (event: UIAnalyticsEvent, analyticsType: string) => {
	if (analyticsType === TRACK_EVENT_TYPE) {
		return last(getActionSubjects(event));
	}
	return last(getComponents(event));
};

const getActionSubjectIdForEventType = (
	event: UIAnalyticsEvent,
	analyticsType: string,
	actionSubject: string | undefined,
) => {
	const name = getName(event);

	// for UI and operational events, the actionSubjectId is generated
	// but for operational events name is optional, since it doesnt always make sense.
	// Consider a high level component BespokeComponent. If this fires an internal event
	// The unique instance of the component is likely to be BespokeComponent (ie there is 1)
	// so if name wasn't optional we would end up with actionSubjectId=bespokeComponentBespokeComponent
	if (analyticsType === OPERATIONAL_EVENT_TYPE) {
		return name ? formatActionSubjectId(name, actionSubject) : actionSubject;
	}
	if (analyticsType === UI_EVENT_TYPE) {
		return formatActionSubjectId(name, actionSubject);
	}

	return getActionSubjectId(event);
};

/**
 * This util exists to bridge the two different formats between atlaskit and the data services events. Two formats are required because
 * atlaskit needs to be able to collect aribtrary events from the tree while the data service event doesn't (and shouldn't) care about the react tree.
 *
 * Atlaskit event format:
 *
 * event {
 *      payload: {
 *          ...attributesFromLowestPointInTheTree
 *      },
 *      context: [{
 *          ...attributesFromHighestPointInTheTree
 *      }, {
 *          ...attributesFromSecondHighestPointInTheTree
 *      }]
 * }
 *
 * Data Services event format:
 *  event {
 *      type: <"TRACK"|"SCREEN"|"UI"|"OPERATIONAL">
 *      payload {
 *          ...mandatoryFieldsBasedOnEventType
 *          ...optionalFieldsBasedOnEventType
 *          attributes: {
 *              ...arbirtaryAttributes
 *          }
 *      }
 *  }
 */
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (event: UIAnalyticsEvent): StructuredAnalytics => {
	const sources = getSources(event);

	const analyticsType = getAnalyticsType(event);
	const actionSubject = getActionSubjectForEventType(event, analyticsType);

	const source = last(sources);
	const action = getAction(event);

	const attributes = {
		...getAttributes(event),
		namespaces: sources.join('.'),
	};

	const containers = getContainers(event);

	if (action === PAGE_EVENT_ACTION) {
		return {
			type: SCREEN_EVENT_TYPE,
			payload: {
				name: source,
				attributes,
				containers,
			},
		};
	}

	switch (analyticsType) {
		case UI_EVENT_TYPE:
		case TRACK_EVENT_TYPE:
		case OPERATIONAL_EVENT_TYPE: {
			const actionSubjectId = getActionSubjectIdForEventType(event, analyticsType, actionSubject);
			const containerId = last(getContainerIds(event));
			const containerType = last(getContainerTypes(event));
			const objectId = last(getObjectIds(event));
			const objectType = last(getObjectTypes(event));
			const tags = getTags(event);
			return {
				type: analyticsType,
				payload: omitBy(
					{
						action,
						actionSubject,
						actionSubjectId,
						source,
						tags,
						containerId,
						containers,
						containerType,
						objectType,
						objectId,
						attributes,
					},
					isUndefined,
				),
			};
		}
		default: {
			return {
				payload: {
					source,
					actionSubject,
					attributes,
					containers,
				},
				type: undefined,
			};
		}
	}
};
