import { installGlobalHandler } from '@atlassian/jira-errors-handling';
import { ff } from '@atlassian/jira-feature-flagging';
import setupUFOGlobalErrorHandler from '@atlassian/ufo-global-error-handler';

// eslint-disable-next-line jira/ff/no-module-level-eval
const isErrorTrackingEnabledWithSentry = ff('fe.platform.error.tracking.enabled');

if (isErrorTrackingEnabledWithSentry) {
	installGlobalHandler();
}

setupUFOGlobalErrorHandler();
