/* eslint-disable no-restricted-properties */
import noop from 'lodash/noop';

const getPerf = () => {
	try {
		// TODO - Write up an explanation to why we are doing this, if this method is approved.
		window.performance.clearResourceTimings ? (window.performance.clearResourceTimings = noop) : '';
		return window.performance;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		// no op implementation
		return {
			now: () => 0,
			mark: noop,
			clearMarks: noop,
			clearResourceTimings: noop,
			getEntriesByType: (_type: string) => [],
			getEntriesByName: (_name: string) => [],
			// Replace with lodash/noop
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			measure: (_measureName: string, _start: string, _end: string) => {},
		};
	}
};
export default getPerf();
