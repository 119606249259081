import { useEffect } from 'react';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { fetchJson } from '@atlassian/jira-fetch';
import { createResource, useResource } from '@atlassian/jira-router';
import { useSavedOrgId } from './utils';

type OrgIdResponse = {
	data: {
		tenantContexts: {
			orgId: string;
		}[];
	};
};

const fetchOrganizationId = async (cloudId: string): Promise<string | null> => {
	const result: OrgIdResponse = await fetchJson('/gateway/api/graphql', {
		method: 'POST',
		body: JSON.stringify({
			query: `
                query OrganizationId($cloudIds: [ID!]) {
                    tenantContexts(cloudIds: $cloudIds) {
                        orgId
                    }
                }
            `,
			variables: { cloudIds: [cloudId] },
		}),
	});

	return result?.data?.tenantContexts?.[0]?.orgId || null;
};

const errorMeta = {
	id: 'organizationId',
	packageName: 'jiraNavigationAppsResources',
};

export const organizationIdResource = createResource<string | null>({
	type: 'ORGANIZATION_ID',
	getKey: () => 'teamOrgState',
	getData: async (_, { tenantContext: { cloudId } }) => {
		try {
			const orgId = await fetchOrganizationId(cloudId);

			if (typeof orgId !== 'string' || !orgId || orgId.length < 1) {
				fireErrorAnalytics({
					meta: errorMeta,
					attributes: {
						hasCloudId: Boolean(cloudId),
						value: String(orgId),
						problem: 'bad-org-id-fetched',
					},
				});
			}

			return orgId;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			fireErrorAnalytics({
				meta: errorMeta,
				error,
				attributes: {
					hasCloudId: Boolean(cloudId),
					value: 'null',
					problem: 'load-failed',
				},
			});
			return null;
		}
	},
	isBrowserOnly: true,
});

export const useOrgId = (): { data: string | undefined } => {
	const { data } = useResource<string | null>(organizationIdResource);
	const [savedOrg, updateOrg] = useSavedOrgId();

	useEffect(() => {
		if (data) {
			updateOrg(data);
		}
	}, [data, updateOrg]);

	return { data: data || savedOrg || undefined };
};
