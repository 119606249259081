import memoize from 'lodash/memoize';
import memoizeOne from 'memoize-one';
import getMeta from '@atlassian/jira-get-meta';
import { getAssetEncoding } from '../../collector/asset-encoding';
import { getBuildVariant } from '../../collector/build-variant';
import { getBundlerVersion } from '../../collector/bundler-version';
import { getUseBifrostGateway } from '../../collector/use-bifrost-gateway';

const customData = () => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const commonCustomData: Record<string, any> = {};
	const shard = getMeta('ajs-shard');
	if (shard !== null && shard !== '') {
		commonCustomData.shard = shard;
	}

	commonCustomData['network:assetEncoding'] = getAssetEncoding();
	const buildVariant = getBuildVariant();
	if (buildVariant) {
		commonCustomData['app:buildVariant'] = buildVariant;
	}

	const bundlerVersion = getBundlerVersion();
	if (bundlerVersion) {
		commonCustomData['app:bundlerVersion'] = bundlerVersion;
	}

	const useBifrostGateway = getUseBifrostGateway();
	if (useBifrostGateway) {
		commonCustomData['app:useBifrostGateway'] = useBifrostGateway;
	}

	commonCustomData.httpProtocol =
		performance
			.getEntriesByType('resource')
			// @ts-expect-error - TS2339  - Property 'nextHopProtocol' does not exist on type 'PerformanceEntry'. | TS2339 - Property 'nextHopProtocol' does not exist on type 'PerformanceEntry'.
			.find(({ nextHopProtocol }) => !!nextHopProtocol)?.nextHopProtocol ?? 'unknown';

	return commonCustomData;
};

export const newGetCustomData = memoize(customData, () => true);

export const getCustomData = memoizeOne(customData);
