import React, { Component, type ReactNode } from 'react';
import type { Store } from 'redux';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import { Provider as ReduxProvider } from '@atlassian/jira-react-redux';

// this will be removed soon

export type WrapperProps = {
	children: ReactNode;
	/**
	 * Will be included in error boundary logs
	 */
	id?: string;
	/**
	 * Will be forwarded to error boundary. Provide to set-up sentry alerts.
	 */
	teamName?: string;
	/**
	 * Will be forwarded to error boundary.
	 */
	packageName?: string;
	/**
	 * Defaults to flag. If to page, will use `ErrorBoundaryPage`
	 */
	fallback?: 'flag' | 'page';
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	store?: Store<any>;
	hasErrorBoundary?: boolean;
};
export type Props = WrapperProps;

/**
 * Acts as a foundational layer for applications, dynamically integrating either an error boundary or a Redux provider based on props. This component ensures that child components have access to application state via Redux and robust error handling through conditional error boundaries. It enhances application stability and state management practices by providing these critical infrastructural features.
 */
// eslint-disable-next-line jira/react/no-class-components
class AppBase extends Component<Props> {
	static defaultProps = {
		id: undefined,
		store: undefined,
		hasErrorBoundary: true,
	};

	renderChildrenWithStore() {
		if (!this.props.store) {
			return this.props.children;
		}
		return <ReduxProvider store={this.props.store}>{this.props.children}</ReduxProvider>;
	}

	render() {
		if (this.props.hasErrorBoundary) {
			return (
				<JSErrorBoundary
					id={this.props.id ?? '@atlassian/jira-app-base::unknown-app'}
					teamName={this.props.teamName}
					// JSErrorBoundary has page as the default, so we opt-out here for now
					fallback={this.props.fallback ?? 'flag'}
					// TODO: packageName should be required. Every call-site
					// should be updated to pass it.
					packageName={this.props.packageName ?? '@atlassian/jira-app-base'}
				>
					{this.renderChildrenWithStore()}
				</JSErrorBoundary>
			);
		}
		return this.renderChildrenWithStore();
	}
}
export default AppBase;
