import { cacheableTypes } from '../../const';

const calculateTransferType = (type: string, duration: number, size: number | undefined) => {
	if (!cacheableTypes.includes(type)) {
		return null;
	}

	if ((size === undefined || size === 0) && duration === 0) {
		return CHRSummary.MEMORY_KEY;
	}
	if (size === 0 && duration > 0) {
		return CHRSummary.DISK_KEY;
	}
	if (size === undefined) {
		return null;
	}

	return CHRSummary.NETWORK_KEY;
};

export class CHRSummary {
	static MEMORY_KEY = 'mem';

	static DISK_KEY = 'disk';

	static NETWORK_KEY = 'net';

	bundles = {
		[CHRSummary.MEMORY_KEY]: 0,
		[CHRSummary.DISK_KEY]: 0,
		[CHRSummary.NETWORK_KEY]: 0,
	};

	bundlesCount = 0;

	size = {
		[CHRSummary.MEMORY_KEY]: 0,
		[CHRSummary.DISK_KEY]: 0,
		[CHRSummary.NETWORK_KEY]: 0,
	};

	sizeTotal = 0;

	add(item: PerformanceResourceTiming) {
		const type = calculateTransferType(item.initiatorType, item.duration, item.transferSize);
		if (type === null) return;

		this.bundles[type] += 1;
		this.bundlesCount += 1;
		this.size[type] += item.encodedBodySize;
		this.sizeTotal += item.encodedBodySize;
	}
}
