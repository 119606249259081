import performance from './performance';

/*
 * This utility finds all performance entries that is prefixed
 * with the `prefix` and does clearMarks on each entry.
 * All the entries that are cleared will be returned
 */

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (prefix: string) => {
	// @ts-expect-error - TS2339 - Property 'getEntries' does not exist on type 'Performance | { now: () => number; mark: (...args: any[]) => void; clearMarks: (...args: any[]) => void; clearResourceTimings: (...args: any[]) => void; getEntriesByType: (type: string) => never[]; getEntriesByName: (name: string) => never[]; }'.
	if (performance && performance.getEntries && performance.clearMarks) {
		return (
			performance
				// @ts-expect-error - TS2339 - Property 'getEntries' does not exist on type 'Performance | { now: () => number; mark: (...args: any[]) => void; clearMarks: (...args: any[]) => void; clearResourceTimings: (...args: any[]) => void; getEntriesByType: (type: string) => never[]; getEntriesByName: (name: string) => never[]; }'.
				.getEntries()
				// @ts-expect-error - TS7006 - Parameter 'mark' implicitly has an 'any' type.
				.filter((mark) => mark.name.startsWith(prefix))
				// @ts-expect-error - TS7006 - Parameter 'mark' implicitly has an 'any' type.
				.map((mark) => {
					performance.clearMarks(mark.name);
					return mark;
				})
		);
	}
	return [];
};
