import { createContext } from 'react';
import type { AdditionalEventAttributes } from '@atlassian/jira-common-experience-tracking-analytics';

// Each `ExperienceTracker` will call these functions to signal to the provider
// the status of a key part of the experience. The provider will aggregate that information
// and determine whether the experience as a whole succeeded or failed.
export type ConsumerCallbacks = {
	onPending: () => void;
	onSuccess: () => void;
	onFailure: (location: string, additionalAttributes?: AdditionalEventAttributes) => void;
	onAbort: (location: string, additionalAttributes?: AdditionalEventAttributes) => void;
};

export type ExperienceContext = {
	consumerCallbacks: ConsumerCallbacks;
	experience: string;
	experienceId: string | null | undefined;
};

export default createContext<ExperienceContext | null>(null);
