/* eslint-disable no-console */

import performance from './performance';
/**
 * Retrieves the first performance entry for a given mark name.
 * This is useful for measuring the performance of specific parts of code.
 * If the performance entry does not exist or the browser does not support the relevant API, it returns null.
 */
export const getMark = (markName: string): PerformanceEntry | null => {
	if (!performance.getEntriesByName) return null;
	const marks = performance.getEntriesByName(markName) || [];
	return marks.length > 0 ? marks[0] : null;
};
export const setMark = (
	markName: string,
	markOptions?: PerformanceMarkOptions,
	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
): PerformanceMark | void => {
	if (!performance.mark) {
		return undefined;
	}
	const entry = performance.mark(markName, markOptions);
	if (console.timeStamp) {
		console.timeStamp(markName);
	}
	return entry;
};
export const setMeasure = (
	measureName: string,
	start: string,
	end: string,
	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
): PerformanceMeasure | void => {
	// performance.measure will throw an exception if either start/end marks are not set
	try {
		return performance.measure(measureName, start, end);
		// eslint-disable-next-line no-empty
	} catch (_err) {}
	return undefined;
};
export function measureFunc<T>(measureName: string, func: () => T): T {
	setMark(`${measureName}:start`);
	const result = func();
	setMark(`${measureName}:end`);
	setMeasure(measureName, `${measureName}:start`, `${measureName}:end`);
	return result;
}
