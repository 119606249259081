import Url from 'url-parse';
import { getCapability, CAPABILITY_HEADER_NAME } from '@atlassian/jira-capabilities';
import { getActiveTraceHttpRequestHeaders } from '@atlassian/ufo-experience-trace-id-context';
/**
 * Generates headers for observability purposes based on the provided URL.
 * It includes capability and tracing information if available.
 * Returns null if no headers are generated.
 */
export const makeObservabilityHeaders = (url: string) => {
	const headers: Record<string, string> = {};
	const capability = getCapability();
	if (capability !== null) {
		headers[CAPABILITY_HEADER_NAME] = capability;
	}
	const tracingHeaders = getActiveTraceHttpRequestHeaders(url);
	if (tracingHeaders !== null) {
		Object.assign(headers, tracingHeaders);
	}
	if (Object.keys(headers).length === 0) return null;
	return {
		headers,
	};
};
/**
 * Conditionally applies generated observability headers to request options.
 * Headers are added only if the request is made to the same hostname (in client-side context)
 * or unconditionally if on the server-side, enhancing cross-origin request observability.
 */
export const applyObservabilityHeaders = (url: string, options: RequestInit): RequestInit => {
	const endpointUrl = new Url(url);
	if (!__SERVER__ && endpointUrl.hostname && endpointUrl.hostname !== window.location.hostname) {
		return options;
	}
	const extraHeaders = makeObservabilityHeaders(url);
	if (extraHeaders === null) return options;
	return {
		...options,
		headers: {
			...options.headers,
			...extraHeaders.headers,
		},
	};
};
